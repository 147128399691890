import pageMenu from './menu.json';
import i18n from './i18n';
import Constants from './constants';
import keyRedirects from './key-redirects.json';
import * as filters from './filters.js';

export {
	pageMenu,
	i18n,
	Constants,
	keyRedirects,
	filters
};

export default {
	pageMenu,
	i18n,
	Constants,
	keyRedirects,
	filters
};
